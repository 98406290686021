import dataScroll from "helpers/dataScroll";

import "./style.scss";

window.addEventListener("DOMContentLoaded", () => {
  dataScroll();

  // Lazy load images
  [].forEach.call(document.querySelectorAll("img[data-src]"), (t) => {
    t.setAttribute("src", t.getAttribute("data-src"));
    t.onload = () => {
      t.removeAttribute("data-src");
    };
  });
});
